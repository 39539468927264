<template>
  <div class="login account">
    <div class="container">
      <div class="email blank" v-if="$route.query.loginType === 'email'">
        <div class="title">
          <b>이메일 로그인</b>
        </div>
        <div class="form">
          <input type="email" class="form-control border-focus-point" autocomplete="off" placeholder="아이디(이메일)" ref="idInputRef" @keyup.enter="submit()" :disabled="!state.loaded"/>
          <input type="password" class="form-control border-focus-point" placeholder="비밀번호" ref="pwInputRef" autocomplete="off" @keyup.enter="submit()" :disabled="!state.loaded"/>
        </div>
        <div class="alert alert-info font-sm" v-if="state.message">{{ state.message }}</div>
        <div class="captcha" v-if="state.captcha.fileName">
          <div class="row">
            <div class="col-lg-6 pr-lg-0 media">
              <div>
                <img :src="state.captcha.filePath + state.captcha.fileName" v-if="state.captcha.type === 'image'"/>
                <template v-else-if="state.captcha.type === 'audio'">
                  <div class="guide font-sm">
                    <i class="fa fa-volume-up" aria-hidden="true"></i>
                  </div>
                  <audio class="hide" autoplay>
                    <source :src="state.captcha.filePath + state.captcha.fileName"/>
                  </audio>
                </template>
              </div>
            </div>
            <div class="buttons col-lg-6">
              <button class="btn font-xs btn-default" @click="setCaptcha('image')">
                <span v-if="state.captcha.type === 'image'">새로고침</span>
                <span v-else>문자 보기</span>
              </button>
              <button class="btn font-xs btn-default" @click="setCaptcha('audio')">
                <span v-if="state.captcha.type === 'audio'">음성 다시 듣기</span>
                <span v-else>음성 듣기</span>
              </button>
            </div>
          </div>
          <input type="text" id="" class="form-control border-focus-point font-sm" :placeholder="state.captcha.type === 'image' ? '이미지의 문자를 입력해주세요.' : state.captcha.type === 'audio' ? '음성의 내용을 입력해주세요.' : ''" @keyup.enter="submit()"
                 ref="captchaInputRef"/>
        </div>
        <div class="actions">
          <button class="btn btn-block btn-point font-sm" @click="submit()" :disabled="!state.loaded">이메일 로그인</button>
        </div>
      </div>
      <div class="kakao blank" v-else>
        <div class="title section">
          <b>간편하게 로그인하여</b>
          <b>오마이컴퍼니 서비스를 이용하세요.</b>
        </div>
        <button class="btn no-shadow" @click="submitBySns('kakao')">
          <span class="ico"></span>
          <span>카카오 로그인</span>
        </button>
      </div>
      <span class="section font-sm">다른 방법으로 로그인 하기</span>
      <div class="sns">
        <div class="icons no-scrollbar">
          <button class="btn no-shadow" :class="s.name" v-for="(s, idx) in computedSnsButtons" :key="idx" :title="`${s.title} 계정으로 로그인`" @click="submitBySns(s.name)"
                  :style="{ backgroundImage: `url(${s.img})` }"></button>
        </div>
      </div>
      <div class="induce induce font-xs">
        <div class="mb-1">
          <span class="desc">아직 오마이컴퍼니 회원이 아니신가요?</span>
          <a class="pointer bold" @click="$store.dispatch('goLoginPage', { join:true })" :disabled="!state.loaded">
            <span>회원가입</span>
          </a>
        </div>
        <template v-if="$route.query.loginType === 'email'">
          <div class="mb-1">
            <span class="desc">아이디가 기억나지 않는다면</span>
            <a class="pointer" @click="$store.commit('openModal',{ name: 'FindId', params: { target: 'id' } })" :disabled="!state.loaded">아이디 찾기</a>
          </div>
          <div class="mb-1">
            <span class="desc">비밀번호가 기억나지 않는다면</span>
            <a class="pointer" @click="$store.commit('openModal',{ name: 'FindPw', params: { target: 'password' } })" :disabled="!state.loaded">비밀번호 찾기</a>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>import {computed, defineComponent, nextTick, onMounted, onUnmounted, reactive, ref} from "@vue/composition-api";
import http from "@/scripts/http";
import env from "@/scripts/env";
import store from "@/scripts/store";
import mixin from "@/scripts/mixin";
import definitions from "@/scripts/definitions";
import track from "@/scripts/track";
import account from "@/scripts/account";
import redirectCallback from "@/scripts/redirectCallback";
import lib from "@/scripts/lib";
import router from "@/scripts/router";
import storage from "@/scripts/storage";

function Component(initialize) {
  this.name = "modalLogin";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      track.post({
        name: "memberLogin",
        category: "회원",
        topic: "로그인 페이지",
        type: "modal",
      });
    });

    const state = reactive({
      checked: false,
      loaded: true,
      destroyRedirectCallback: true,
      message: "",
      captcha: {
        filePath: "",
        fileName: "",
        type: "",
        value: "",
        nKey: "",
        sKey: "",
      }
    });

    const idInputRef = ref();
    const pwInputRef = ref();
    const captchaInputRef = ref();

    const computedSnsButtons = computed(() => {
      const result = [];

      definitions.sns.buttons.forEach(b => {
        switch (b.name) {
          case "google":
            !env.omcApp && result.push(b);
            break;

          case "apple":
            result.push(b);
            break;

          case "kakao":
            router.app.$route.query.loginType === "email" && result.push(b);
            break;

          default:
            result.push(b);
            break;
        }
      });

      if (router.app.$route.query.loginType !== "email") {
        result.push({
          name: "email",
          title: "이메일",
          img: "/assets/ico/page.login.email.svg"
        });
      }

      return result;
    });

    const focus = () => {
      if (loginIdStorage) {
        state.checked = true;
        nextTick(() => {
          idInputRef.value.value = loginIdStorage;
          env.device === "desktop" && pwInputRef.value?.focus();
        });
      } else {
        nextTick(() => {
          env.device === "desktop" && idInputRef.value?.focus();
        });
      }
    };

    const emailLogin = () => {
      const query = lib.getRenewed(router.app.$route.query);

      query.loginType = "email";
      router.push({query});

      focus();
    };

    const setCaptcha = (type, {nKey, sKey} = {}) => {
      state.captcha.nKey = nKey || state.captcha.nKey;
      state.captcha.sKey = sKey || state.captcha.sKey;

      const args = {
        captchaNKey: state.captcha.nKey,
        captchaSKey: state.captcha.sKey,
        captchaType: type
      };

      state.captcha.type = "";

      http.get(`/api/member/login/captcha-file`, args).then(({data}) => {
        state.captcha.filePath = data.body.captchaFilePath;
        state.captcha.fileName = data.body.captchaFileName;
        state.captcha.type = data.body.captchaType;
      });
    };

    const loginIdStorage = storage.get("local", "loginId");

    const submit = () => {
      const loginId = idInputRef.value.value ? idInputRef.value.value.trim() : "";
      const loginPw = pwInputRef.value.value;

      if (!loginId) {
        store.commit("setSwingMessage", "아이디를 입력해주세요.");
        idInputRef.value.focus();
        return;
      } else if (loginId.length < 4) {
        store.commit("setSwingMessage", "아이디는 4자 이상이어야 합니다.");
        idInputRef.value.focus();
        return;
      } else if (!loginPw || !loginPw.trim()) {
        store.commit("setSwingMessage", "비밀번호를 입력해주세요.");
        pwInputRef.value.focus();
        return;
      } else if (state.captcha.fileName && !captchaInputRef.value.value) {
        store.commit("setSwingMessage", "자동 입력 방지 문자를 입력해주세요.");
        captchaInputRef.value.focus();
        return;
      }

      if (state.checked) {
        storage.set("local", "loginId", loginId);
      } else {
        storage.remove("local", "loginId");
      }

      const args = {
        memberId: loginId,
        passwd: loginPw,
      };

      if (state.captcha.fileName) {
        args.captchaNKey = state.captcha.nKey;
        args.captchaSKey = state.captcha.sKey;
        args.captchaVal = captchaInputRef.value.value.trim();
        args.captchaType = state.captcha.type;
      }

      state.message = "";
      account.loginByEmail(args, () => {
        state.destroyRedirectCallback = false;
      }, (data) => {
        state.captcha.fileName = "";
        store.commit("setSwingMessage", data.message);

        switch (data?.body?.code) {
          case "DUPLICATED_EMAIL":
            state.message = data.message;
            break;

          case "EXE_CAPTCHA_VERITY" :
          case "NOT_VALID_CAPTCHA_INPUT_VALUE" :
            state.message = data.message;
            setCaptcha("image", {
              nKey: data.body.captchaNKey,
              sKey: data.body.captchaSKey,
            });
            break;

          case "EMAIL_AUTHENTICATION":
            store.dispatch("redirect", () => {
              store.commit("openModal", {
                name: "Email",
                params: {
                  memberSeq: data.body.params?.memberSeq
                }
              });
            });
            break;

          case "NEW_PASSWD":
            store.dispatch("redirect", () => {
              store.commit("openModal", {
                name: "Password",
                params: {
                  memberId: args.memberId,
                  tmpPassword: args.passwd
                }
              });
            });
            break;
        }
      });
    };

    const submitBySns = (snsType) => {
      state.destroyRedirectCallback = false;

      if (snsType === "email") {
        return emailLogin();
      }

      account.loginAtSnsPage(snsType, "login");
    };

    onMounted(() => {
      if (router.app.$route.query.loginType !== "email") {
        return;
      }

      focus();
    });

    onUnmounted(() => {
      state.destroyRedirectCallback && redirectCallback.destroy();
      store.commit("setCustomListener", ["appleIdSignInOnSuccess", undefined]);
      store.commit("setCustomListener", ["appleIdSignInOnFailure", undefined]);
    });

    return {component, state, idInputRef, pwInputRef, captchaInputRef, computedSnsButtons, submit, submitBySns, setCaptcha};
  },
});
</script>

<style lang="scss" scoped>
@import "../../styles/page.account.scss";

.login {
  > .container {
    > .email {
      position: relative;

      .title {
        margin-bottom: $px15;
      }

      > .alert {
        padding: $px15;
        margin-bottom: $px13;
      }

      .form {
        input {
          &:not([type=checkbox]) {
            margin: $px13 0;
            height: $formHeightLg;

            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus {
              -webkit-box-shadow: 0 0 0 1000px $colorBackground inset !important;
            }
          }
        }
      }

      .captcha {
        padding-bottom: $px15;

        .row > div {
          margin-bottom: $px15;

          img {
            border-radius: $px4;
            width: 100%;
          }

          &.media > div {
            background: $colorBackground;
            width: 100%;
            height: 100%;

            img {
              border: $px1 solid #eee;
              border-radius: $px4;
            }

            .guide {
              position: relative;
              border-radius: $px4;
              height: 100%;

              > i {
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                font-size: $px30;
                text-align: center;
                width: 100%;
              }
            }
          }

          &.buttons {
            .btn {
              padding: $px19 $px10;
              width: 100%;

              &:first-child {
                margin-bottom: $px8;
              }
            }
          }
        }

        input {
          text-transform: uppercase;
          height: $formHeight;
        }
      }
    }
  }
}
</style>