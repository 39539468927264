<template>
  <div class="admin" :class="{collapsed: state.layout.collapsed}">
    <template v-if="$store.state.account.loggedIn">
      <template v-if="computedManager">
        <div class="wrapper">
          <Aside :menus="computedMenus" :breadcrumbs="computedBreadcrumbs" :toggle="toggle" :collapsed="state.layout.collapsed" v-if="computedBreadcrumbs.length"/>
          <Header :toggle="toggle" :collapsed="state.layout.collapsed"/>
          <Main :breadcrumbs="computedBreadcrumbs">
            <RouterView/>
          </Main>
          <Footer page="admin"/>
        </div>
      </template>
      <div class="no-auth" v-else>
        <div>
          <img src="/assets/ico/common.warning.sign.svg"/>
          <div class="pt-3">이 페이지에 접근할 권한이 없습니다.</div>
          <div class="pt-3">
            <router-link class="color-anchor" to="/">홈으로</router-link>
            <a class="pointer ml-3" @click="$store.dispatch('logoutAccount')">
              <span class="color-anchor">로그아웃</span>
            </a>
          </div>
        </div>
      </div>
      <Climbing/>
    </template>
    <NoLogin v-else/>
  </div>
</template>

<script>
import {computed, defineComponent, onMounted, onUnmounted, reactive} from "@vue/composition-api";
import store from "@/scripts/store";
import Header from "@/layouts/admin/Header";
import Aside from "@/layouts/admin/Aside";
import Main from "@/layouts/admin/Main";
import Footer from "@/components/Footer";
import mixin from "@/scripts/mixin";
import NoLogin from "@/components/NoLogin";
import Climbing from "@/components/Climbing";
import menus from "@/texts/admin/menus.json";
import lib from "@/scripts/lib";
import env from "@/scripts/env";
import router from "@/scripts/router";
import storage from "@/scripts/storage";

function Component(initialize) {
  this.name = "layoutAdmin";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {Climbing, Main, NoLogin, Header, Aside, Footer},
  setup() {
    const component = new Component(() => {
      if (!store.state.account.loggedIn) {
        // return store.commit("openModal", {
        //   name: "Login",
        //   routerReplace: true
        // });

        return store.dispatch("goLoginPage", {replace: true});
      }

      if (window.opener || storage.get("local", "adminLayoutAsideCollapsed")) {
        state.layout.collapsed = true;
      }
    });

    const state = reactive({
      layout: {
        collapsed: env.device === "mobile",
      },
    });

    const argonHref = "/assets/lib/argon/argon.min.css";
    const nucleoHref = "/assets/lib/nucleo/css/nucleo.css";

    const computedMenus = computed(() => {
      const newMenus = lib.getRenewed(menus);

      const setMenus = (menus) => {
        for (let i in menus) {
          menus[i].path = menus[i].path.replace("{omcTeam}", store.state.account.omcTeam || "").replace("{memberSeq}", store.state.account.memberSeq || "");

          if (menus[i].children?.length) {
            setMenus(menus[i].children);
          }
        }
      };

      setMenus(newMenus);
      return newMenus;
    });

    const computedManager = computed(() => {
      return store.state.account.memberAuth === "MEMBER_MID_ADMIN" || store.state.account.memberAuth === "MEMBER_TOP_ADMIN";
    });

    const computedBreadcrumbs = computed(() => {
      const paths = router.app.$route.path?.split("/") || [];
      const breadcrumbs = [];
      let found = false;
      let path;

      //url에서 파라미터 제거
      if (Object.keys(router.app.$route.params)?.length) {
        for (let i in router.app.$route.params) {
          for (let j in paths) {
            if (router.app.$route.params[i] === paths[j]) {
              paths.splice(j, 1);
            }
          }
        }
      }
      path = paths.join("/");

      const setBreadcrumbs = (menus) => {
        for (let m of menus) {
          if (Array.isArray(m.children) && m.children.length) {
            setBreadcrumbs(m.children);

            if (found) {
              breadcrumbs.push(m);
              return;
            }
          } else if (m.path.split("?")[0] === path || (path[path.length - 1] === "/" && path.slice(0, path.length - 1) === m.path.split("?")[0])) {
            found = true;
            breadcrumbs.push(m);
            break;
          }
        }
      };

      setBreadcrumbs(computedMenus.value);
      return breadcrumbs;
    });

    const toggle = () => {
      if (!state.layout.collapsed) {
        storage.set("local", "adminLayoutAsideCollapsed", "collapsed");
      } else {
        storage.remove("local", "adminLayoutAsideCollapsed");
      }

      state.layout.collapsed = !state.layout.collapsed;
    };

    onMounted(() => {
      if (!store.state.account.loggedIn || !computedManager.value) {
        return;
      }

      store.dispatch("appendLink", {href: nucleoHref});
      store.dispatch("appendLink", {href: argonHref});
    });

    onUnmounted(() => {
      document.head.querySelector(`link[href='${argonHref}']`)?.remove();
      document.head.querySelector(`link[href='${nucleoHref}']`)?.remove();
    });

    return {component, state, computedMenus, computedBreadcrumbs, computedManager, menus, toggle};
  },
});
</script>

<style lang="scss" scoped>
.admin {
  color: #525f7f;

  > .wrapper {
    background-color: #f8f9fe;
    padding-left: $px250;
    transition: padding-left 0.25s;
    position: relative;

    .btn {
      box-shadow: none;
    }

    .view {
      margin-top: $px-60;
    }

    &.no-login {
      padding-left: 0;
    }
  }

  > .no-auth {
    display: table;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;

    > div {
      display: table-cell;
      vertical-align: middle;

      img {
        height: $px50;
      }
    }
  }

  &.collapsed > .wrapper {
    padding-left: 0;
  }

  @media(max-width: 991px) {
    > .wrapper {
      padding-left: 0;
    }
  }
}
</style>