import { render, staticRenderFns } from "./JoinStep2.vue?vue&type=template&id=02b55dba&scoped=true"
import script from "./JoinStep2.vue?vue&type=script&lang=js"
export * from "./JoinStep2.vue?vue&type=script&lang=js"
import style0 from "./JoinStep2.vue?vue&type=style&index=0&id=02b55dba&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02b55dba",
  null
  
)

export default component.exports