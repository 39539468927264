import VueRouter from "vue-router";

// main
import Home from "../pages/main/Home";
import SearchResult from "../pages/main/SearchResult";

// guide
import GuideMain from "../pages/guide/Main";
import GuideCommon from "../pages/guide/Common";
import GuideGuestReward from "../pages/guide/GuestReward";

// planning
import PlanningReward from "../pages/planning/Reward";

// my-page
import MyPage from "../pages/my-page/Index";

// channel
import Channel from "../pages/channel/Index";

// community
import Notice from "@/pages/community/Notice";
import FundingTip from "@/pages/community/FundingTip";

// reservation
import ReservationList from "../pages/reservation/List";
import ReservationDetail from "../pages/reservation/Detail";

// account
import Login from "@/pages/account/Login";
import Join from "@/pages/account/Join";

// library
import Vue from "vue";

// VueMeta
import Meta from "vue-meta";
// etc
import Catch from "@/pages/etc/Catch";
import Error from "@/pages/etc/Error";
import Callback from "@/pages/etc/Callback";
import Sitemap from "../pages/etc/Sitemap";
import Search from "@/pages/etc/Search";
import Passport from "@/pages/etc/Passport";
import Slides from "@/pages/etc/Slides";
import storage from "@/scripts/storage";

// reward
const RewardList = () => import("../pages/reward/List");
const RewardDetail = () => import("../pages/reward/Detail");
const RewardOrderForm = () => import("../pages/reward/OrderForm");
const RewardOrderResult = () => import("../pages/reward/OrderResult");

// invest
const InvestList = () => import("../pages/invest/List");
const InvestDetail = () => import("../pages/invest/Detail");
const InvestOrderSelect = () => import("../pages/invest/OrderForm");
const InvestOrderResult = () => import("../pages/invest/OrderResult");
const InvestChangeHistory = () => import("../pages/invest/ChangeHistory");

// mock
const MockOrderForm = () => import("../pages/mock/OrderForm");
const MockOrderResult = () => import("../pages/mock/OrderResult");

// company
const Vision = () => import("../pages/company/Vision");

// policy
const Terms = () => import("../pages/policy/Terms");
const RewardTerms = () => import("../pages/policy/RewardTerms");
const InvestTerms = () => import("../pages/policy/InvestTerms");
const RewardPrivacy = () => import("../pages/policy/RewardPrivacy");
const InvestPrivacy = () => import("../pages/policy/InvestPrivacy");

// apply
const ApplyIntro = () => import("../pages/apply/Intro");
const RewardIntro = () => import("../pages/apply/RewardIntro");
const RewardApply = () => import("../pages/apply/RewardApply");
const RewardApplyDone = () => import("../pages/apply/RewardApplyDone");
const InvestIntro = () => import("../pages/apply/InvestIntro");
const InvestApply = () => import("../pages/apply/InvestApply");
const InvestApplyDone = () => import("../pages/apply/InvestApplyDone");

// partnership
const PartnershipList = () => import("../pages/partnership/List");
const PartnershipDetail = () => import("../pages/partnership/Detail");

// point
const PointGenerate = () => import("../pages/point/Generate");

// manage
const ManageHome = () => import("../pages/manage/Home");
const ManageNews = () => import("../pages/manage/News");
const ManageIntroduceCheer = () => import("../pages/manage/IntroduceCheer");
const ManageStory = () => import("../pages/manage/Story");
const ManageQna = () => import("../pages/manage/Qna");
const ManageCheer = () => import("../pages/manage/Cheer");
const ManageInvestor = () => import("../pages/manage/Investor");
const ManageInvestorDetail = () => import("../pages/manage/InvestorDetail");
const ManageStats = () => import("../pages/manage/Stats");
const ManageStatsInvestorBySort = () => import("../pages/manage/StatInvestorBySort");
const ManageRewards = () => import("../pages/manage/Rewards");
const ManagePayMethod = () => import("../pages/manage/PayMethod");
const ManageDelivery = () => import("../pages/manage/Delivery");
const ManageCalculateOld = () => import("../pages/manage/CalculateOld");
const ManageCalculate = () => import("../pages/manage/Calculate");
const ManageManager = () => import("../pages/manage/Manager");
const ManageCrowdSourcing = () => import("../pages/manage/CrowdSourcing");
const ManagePickCalendarStatus = () => import("../pages/manage/PickCalendarStatus.vue");
const ManageReview = () => import("../pages/manage/Review");

// admin
const AdminHome = () => import("../pages/admin/Home");
const AdminFirewall = () => import("../pages/admin/etc/Firewall");
const AdminUtmGenerator = () => import("../pages/admin/tools/UtmGenerator");
const AdminExtracts = () => import("../pages/admin/tools/Extracts");
const AdminModelGenerator = () => import("../pages/admin/developments/ModelGenerator");
const AdminCommits = () => import("../pages/admin/developments/Commits");
const AdminStatsUtm = () => import("../pages/admin/stats/Utm");
const AdminStatsAnnual = () => import("../pages/admin/stats/Annual");
const AdminUndeveloped = () => import("../pages/admin/etc/Undeveloped");
const AdminWeeklyTodo = () => import("../pages/admin/tools/WeeklyTodo");
const AdminMemberList = () => import("../pages/admin/member/MemberList");
const AdminMemberDetail = () => import("../pages/admin/member/MemberDetail");
const AdminReviewList = () => import("../pages/admin/review/ReviewList");
const AdminFormCategories = () => import("../pages/admin/form/Categories");
const AdminFormForms = () => import("../pages/admin/form/Forms");
const AdminFormApplications = () => import("../pages/admin/form/Applications");
const AdminArchiveCategories = () => import("../pages/admin/archive/Categories");
const AdminArchiveForms = () => import("../pages/admin/archive/Forms");
const AdminArchiveApplications = () => import("../pages/admin/archive/Applications");
const AdminCalculateChatList = () => import("../pages/admin/calculate/CalculateChatList");
const AdminCalculateStatusList = () => import("../pages/admin/calculate/CalculateStatusList");
const AdminPause = () => import("../pages/admin/invest/Pause");
const AdminReservations = () => import("../pages/admin/project/Reservations");
const AdminQna = () => import("../pages/admin/board/Qna");
const AdminPush = () => import("../pages/admin/push/PushList");
const AdminCoupons = () => import("../pages/admin/coupon/Coupons");
const AdminCoupon = () => import("../pages/admin/coupon/Coupon");

// form
const FormApplication = () => import("../pages/form/Application");
const FormApplicationFinished = () => import("../pages/form/ApplicationFinished");
const FormAccordion = () => import("../pages/form/Accordion");
const FormApplications = () => import("../pages/form/Applications");

// archive
const ArchiveApplication = () => import("../pages/archive/Application");
const ArchiveApplicationFinished = () => import("../pages/archive/ApplicationFinished");
const ArchiveAccordion = () => import("../pages/archive/Accordion");
const ArchiveApplications = () => import("../pages/archive/Applications");

// map
const MapMarkers = () => import("../pages/map/Makers");

// landing
const LandingBuySocial230613 = () => import("../pages/landing/BuySocial230613");

Vue.use(Meta, {
  attribute: "data-vue-meta",
  tagIDKeyName: "vmid",  // 변화시키는 구분 값
  refreshOnceOnNavigation: true,
});

const router = new VueRouter({
  mode: "history",
  scrollBehavior(to, from) {
    // 프로젝트 상세 탭의 이동, 모달 닫았을 때 등의 이유로 아래와 같이 처리
    if (to.path === from.path) {
      return {};
    }

    return {x: 0, y: 0};
  },
  // scrollBehavior(to, from, savedPosition) {
  //   if (to.hash) {
  //     console.log(1);
  //     return new Promise((resolve) => {
  //       !to.hash.includes("=") && setTimeout(() => {
  //         console.log(5);
  //         resolve({selector: to.hash});
  //       }, 100);
  //     });
  //   } else if (savedPosition) {
  //     console.log(savedPosition);
  //     return savedPosition;
  //   } else if (to.path === from.path) {
  //     console.log(3);
  //     return {};
  //   }
  //   console.log(4);
  //
  //   return {x: 0, y: 0};
  // },
  routes: [
    {
      path: "/",
      component: Home,
    },
    {
      path: "/main",
      component: Home,
    },
    {
      path: "/main/search",
      component: SearchResult,
    },
    {
      path: "/reward/list",
      component: RewardList,
    },
    {
      path: "/reward/:projectSeq",
      component: RewardDetail,
    },
    {
      path: "/reward/preview/:projectSeq",
      component: RewardDetail,
    },
    {
      path: "/reward/:projectSeq/order",
      component: RewardOrderForm,
    },
    {
      path: "/reward/:projectSeq/order/:investorSeq",
      component: RewardOrderResult,
    },
    {
      path: "/reward/:projectSeq/:tab",
      component: RewardDetail,
    },
    {
      path: "/invest/list",
      component: InvestList,
    },
    {
      path: "/invest/:projectSeq",
      component: InvestDetail,
    },
    {
      path: "/invest/preview/:projectSeq",
      component: InvestDetail,
    },
    {
      path: "/invest/:projectSeq/order",
      component: InvestOrderSelect,
    },
    {
      path: "/invest/:projectSeq/:tab",
      component: InvestDetail,
    },
    {
      path: "/invest/:projectSeq/done/:subscribeSeq",
      component: InvestOrderResult,
    },
    {
      path: "/invest/:projectSeq/changeHistory/:historySeq",
      component: InvestChangeHistory,
    },
    {
      path: "/mock/:projectSeq/order",
      component: MockOrderForm,
    },
    {
      path: "/mock/:projectSeq/result/:investorSeq",
      component: MockOrderResult,
    },
    {
      path: "/company/vision",
      component: Vision,
    },
    {
      path: "/policy/terms",
      component: Terms,
    },
    {
      path: "/policy/rewardTerms",
      component: RewardTerms,
    },
    {
      path: "/policy/investTerms",
      component: InvestTerms,
    },
    {
      path: "/policy/rewardPrivacy",
      component: RewardPrivacy,
    },
    {
      path: "/policy/investPrivacy",
      component: InvestPrivacy,
    },
    {
      path: "/sitemap",
      component: Sitemap,
    },
    {
      path: "/guide/main",
      component: GuideMain,
    },
    {
      path: "/guide/common",
      component: GuideCommon,
    },
    {
      path: "/guide/guest/reward",
      component: GuideGuestReward,
    },
    {
      path: "/apply/intro",
      component: ApplyIntro,
    },
    {
      path: "/apply/rewardIntro",
      component: RewardIntro,
    },
    {
      path: "/apply/investIntro",
      component: InvestIntro,
    },
    {
      path: "/apply/rewardApplyDone",
      component: RewardApplyDone,
    },
    {
      path: "/apply/investApply/:applicationSeq?",
      component: InvestApply,
    },
    {
      path: "/apply/investApplyDone",
      component: InvestApplyDone,
    },
    {
      path: "/apply/:projectSeq",
      component: RewardApply
    },
    {
      path: "/partnership/:tab",
      component: PartnershipList,
    },
    {
      path: "/partnership/:contestSeq/dashboard",
      component: PartnershipDetail,
    },
    {
      path: "/partnership/:contestSeq/:projectType",
      component: PartnershipDetail,
    },
    {
      path: "/planning/:planningSeq/:projectType",
      component: PlanningReward,
    },
    {
      path: "/mypage/:param1?/:param2?/:param3?",
      component: MyPage,
    },
    {
      path: "/channel/:makerSeq",
      component: Channel,
    },
    {
      path: "/community/notice/:seq?",
      component: Notice,
    },
    {
      path: "/community/fundingtip/:seq?",
      component: FundingTip,
    },
    {
      path: "/reservation/list",
      component: ReservationList
    },
    {
      path: "/reservation/:projectSeq",
      component: ReservationDetail,
    },
    {
      path: "/point/:couponId/generate",
      component: PointGenerate,
    },
    {
      path: "/manage/:projectType/:projectSeq/news",
      component: ManageNews,
    },
    {
      path: "/manage/:projectType/:projectSeq/qna",
      component: ManageQna,
    },
    {
      path: "/manage/:projectType/:projectSeq/cheer",
      component: ManageCheer,
    },
    {
      path: "/manage/:projectType/:projectSeq/introduceCheer",
      component: ManageIntroduceCheer,
    },
    {
      path: "/manage/:projectType/:projectSeq/crowd-sourcing",
      component: ManageCrowdSourcing,
    },
    {
      path: "/manage/:projectType/:projectSeq/pick-calendar-status",
      component: ManagePickCalendarStatus,
    },
    {
      path: "/manage/:projectType/:projectSeq/story",
      component: ManageStory,
    },
    {
      path: "/manage/:projectType/:projectSeq/investor/:investorSeq",
      component: ManageInvestorDetail,
    },
    {
      path: "/manage/:projectType/:projectSeq/investor",
      component: ManageInvestor,
    },
    {
      path: "/manage/:projectType/:projectSeq/stats/rewards",
      component: ManageRewards,
    },
    {
      path: "/manage/:projectType/:projectSeq/stats/paymethod",
      component: ManagePayMethod,
    },
    {
      path: "/manage/:projectType/:projectSeq/stats/investorBySort",
      component: ManageStatsInvestorBySort,
    },
    {
      path: "/manage/:projectType/:projectSeq/stats",
      component: ManageStats,
    },
    {
      path: "/manage/:projectType/:projectSeq/delivery",
      component: ManageDelivery,
    },
    {
      path: "/manage/:projectType/:projectSeq/stats",
      component: ManageHome,
    },
    {
      path: "/manage/:projectType/:projectSeq/calculate",
      component: ManageCalculateOld,
    },
    {
      path: "/manage/:projectType/:projectSeq/calculateNew",
      component: ManageCalculate,
    },
    {
      path: "/manage/:projectType/:projectSeq/manager",
      component: ManageManager,
    },
    {
      path: "/manage/:projectType/:projectSeq",
      component: ManageHome,
    },
    {
      path: "/manage/:projectType/:projectSeq/review",
      component: ManageReview,
    },
    {
      path: "/new-admin",
      component: AdminHome,
    },
    {
      path: "/new-admin/firewall",
      component: AdminFirewall,
    },
    {
      path: "/new-admin/member/members",
      component: AdminMemberList,
    },
    {
      path: "/new-admin/member/members/:memberSeq/:tab?",
      component: AdminMemberDetail,
    },
    {
      path: "/new-admin/review",
      component: AdminReviewList,
    },
    {
      path: "/new-admin/stats/utm",
      component: AdminStatsUtm,
    },
    {
      path: "/new-admin/stats/annual",
      component: AdminStatsAnnual,
    },
    {
      path: "/new-admin/form/categories",
      component: AdminFormCategories,
    },
    {
      path: "/new-admin/form/forms",
      component: AdminFormForms,
    },
    {
      path: "/new-admin/form/applications",
      component: AdminFormApplications,
    },
    {
      path: "/new-admin/archive/categories",
      component: AdminArchiveCategories,
    },
    {
      path: "/new-admin/archive/forms",
      component: AdminArchiveForms,
    },
    {
      path: "/new-admin/archive/applications",
      component: AdminArchiveApplications,
    },
    {
      path: "/new-admin/tools/utm",
      component: AdminUtmGenerator,
    },
    {
      path: "/new-admin/tools/extracts",
      component: AdminExtracts,
    },
    {
      path: "/new-admin/developments/model",
      component: AdminModelGenerator,
    },
    {
      path: "/new-admin/developments/commits",
      component: AdminCommits,
    },
    {
      path: "/new-admin/tools/todo",
      component: AdminWeeklyTodo,
    },
    {
      path: "/new-admin/calculate/calculateChatList",
      component: AdminCalculateChatList,
    },
    {
      path: "/new-admin/calculate/calculateStatusList",
      component: AdminCalculateStatusList,
    },
    {
      path: "/new-admin/project/invest/pause",
      component: AdminPause,
    },
    {
      path: "/new-admin/project/reservations",
      component: AdminReservations,
    },
    {
      path: "/new-admin/board/qnas",
      component: AdminQna,
    },
    {
      path: "/new-admin/push",
      component: AdminPush,
    },
    {
      path: "/new-admin/coupons",
      component: AdminCoupons,
    },
    {
      path: "/new-admin/coupons/:couponId",
      component: AdminCoupon,
    },
    {
      path: "/new-admin/:catchAll(.*)",
      component: AdminUndeveloped,
    },
    {
      path: "/form/categories/:id",
      component: FormAccordion,
    },
    {
      path: "/form/applications",
      component: FormApplications,
    },
    {
      path: "/forms/:name/finished",
      component: FormApplicationFinished,
    },
    {
      path: "/forms/:name?",
      component: FormApplication,
    },
    {
      path: "/forms/:name?/applications/:applicationId?",
      component: FormApplication,
    },
    {
      path: "/archive/categories/:id",
      component: ArchiveAccordion,
    },
    {
      path: "/archive/applications",
      component: ArchiveApplications,
    },
    {
      path: "/archives/:name/finished",
      component: ArchiveApplicationFinished,
    },
    {
      path: "/archives/:name?",
      component: ArchiveApplication,
    },
    {
      path: "/archives/:name?/applications/:applicationId?",
      component: ArchiveApplication,
    },
    {
      path: "/maps/:name/:id",
      component: MapMarkers,
    },
    {
      path: "/landings/buy-social-230613",
      component: LandingBuySocial230613,
    },
    {
      path: "/admin/:catchAll(.*)",
      redirect: "/new-admin"
    },
    {
      path: "/admin",
      redirect: "/new-admin"
    },
    {
      path: "/search",
      component: Search
    },
    {
      path: "/login",
      component: Login
    },
    {
      path: "/login/form",
      redirect: "/login"
    },
    {
      path: "/join",
      component: Join
    },
    {
      path: "/join/type",
      redirect: "/join"
    },
    {
      path: "/passport",
      component: Passport
    },
    {
      path: "/popup/slides",
      component: Slides,
    },
    {
      path: "/callback/:param1/:param2?",
      component: Callback,
    },
    {
      path: "/error/:code?",
      component: Error,
    },
    {
      path: "/:catchAll(.*)",
      component: Catch,
    }
  ]
});

router.beforeEach((to, from, next) => {
  if ((to.fullPath.includes("/point")
          && to.fullPath.includes("/generate"))
      || to.fullPath.includes("/login")
      || to.fullPath.includes("/join")) {
    return next();
  }

  const lang = from.query.lang || to.query.lang;

  if (lang && !to.query.lang) {
    next({
      path: to.path,
      query: {...to.query, lang},
      hash: to.hash,
    });
  } else {
    next();
  }

  storage.set("session", "redirectPath", to.fullPath);
});

export default router;