<template>
  <footer class="footer" ref="footerRef" :data-page="page" :class="$store.state.classes.footer">
    <div class="container">
      <template v-if="!['manage', 'admin', 'form'].includes(page)">
        <div class="notice clearfix" ref="noticeRef">
          <div class="title">
            <router-link to="/community/notice" class="no-underline">공지사항</router-link>
          </div>
          <div class="articles" @mouseenter="clear()" @mouseover="clear()" @mouseleave="resume()">
            <ul class="tight" :style="{ marginTop: state.notice.idx * state.notice.height * -1 + 'px' }">
              <li v-for="(n, idx) in state.notice.list" :key="idx">
                <router-link :to="`/community/notice/${n.noticeSeq}`" class="pointer ellipsis">{{ n.title }}</router-link>
              </li>
            </ul>
          </div>
          <!--          <div class="control">-->
          <!--            <span class="up pointer" @click="move(-1)" style="background-image: url(/assets/ico/component.footer.arrow.up.png)"></span>-->
          <!--            <span class="down pointer" @click="move(1)" style="background-image: url(/assets/ico/component.footer.arrow.down.png)"></span>-->
          <!--          </div>-->
        </div>
        <div class="links clearfix">
          <ul class="menus tight">
            <li>
              <router-link to="/">홈으로</router-link>
            </li>
            <li>
              <a :href="$definitions.urls.companyIntro" target="_blank" rel="noopener noreferrer">회사 소개</a>
            </li>
            <li>
              <router-link to="/policy/terms">이용약관</router-link>
            </li>
            <li>
              <router-link to="/policy/rewardPrivacy">개인정보보호정책</router-link>
            </li>
            <li>
              <a :href="$definitions.urls.generalGuide" target="_blank" rel="noopener noreferrer">이용가이드</a>
            </li>
          </ul>
          <ul class="stores" v-if="!$env.omcApp">
            <li>
              <a class="btn font-xs" href="https://play.google.com/store/apps/details?id=com.ohmycompany.app" target="_blank" rel="noopener noreferrer">
                <span class="img" style="background-image: url(/assets/ico/component.footer.sns.googleplay.svg)"></span>
                <span>Google Play</span>
              </a>
            </li>
            <li>
              <a class="btn font-xs" href="https://apps.apple.com/kr/app/apple-store/id1434043837" target="_blank" rel="noopener noreferrer">
                <span class="img" style="background-image: url(/assets/ico/component.footer.sns.appstore.svg)"></span>
                <span>App Store</span>
              </a>
            </li>
          </ul>
        </div>
      </template>
      <div class="brands clearfix">
        <router-link to="/">
          <img src="/assets/img/common.logo.svg" alt="로고"/>
        </router-link>
        <ul class="sns tight">
          <li :class="s.class" v-for="(s, idx) in snsAttrs" :key="idx">
            <a :href="s.url" :title="s.title" :aria-label="s.title" target="_blank" rel="noopener noreferrer">
              <span class="img" :style="s.style"></span>
            </a>
          </li>
        </ul>
      </div>
      <div class="texts">
        <address>
          <div>서울시 서대문구 이화여대8길 3 연화프라자 3층</div>
          <span>
            <span>대표번호: </span>
            <a href="tel:023882556">02-388-2556</a>
          </span>
          <span>대표자명: 성진경, 한송이</span>
          <span>사업자등록번호: 209-81-56911</span>
          <span>통신판매업신고: 제2018-서울은평-0376호</span>
          <span>
            <span>이메일: </span>
            <a href="mailto:omc@ohmycompany.com">omc@ohmycompany.com</a>
          </span>
        </address>
        <div class="info">
          <div class="warning">㈜오마이컴퍼니는 크라우드펀딩 플랫폼을 운영하는 중개자(온라인소액투자중개업, 통신판매중개자)로서 <!--크라우드펀딩--> 프로젝트를 진행하는 당사자가 아니기에, 투자로 인한 손실 보전, 리워드 제공을 담보해 드리지 못합니다.</div>
          <div>© {{ currYear }}. 오마이컴퍼니. All rights reserved.</div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import {defineComponent, nextTick, onUnmounted, reactive, ref} from "@vue/composition-api";
import http from "@/scripts/http";
import mixin from "@/scripts/mixin";
import {httpError} from "@/scripts/httpError";

function Component(initialize) {
  this.name = "componentFooter";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  props: {
    page: String
  },
  setup(props) {
    const component = new Component(() => {
      if (["manage", "admin", "form"].includes(props.page)) {
        return;
      }

      const args = {
        pageIndex: 1,
        pageSize: 5,
        pageUnit: 5,
        firstIndex: 1,
        recordCountPerPage: 5,
        noticeFlag: "Y"
      };

      http.get("/api/notices", args).then(({data}) => {
        state.notice.list = data.body.list;

        nextTick(() => {
          const ul = noticeRef.value.querySelector(".articles ul");
          const lists = ul.querySelectorAll("li");

          if (!lists.length) {
            return;
          }

          state.notice.height = lists[0].offsetHeight;
          state.notice.length = ul.querySelectorAll("li").length;
          resume();
        });
      }).catch(httpError());
    });

    const state = reactive({
      notice: {
        idx: 0,
        height: 0,
        length: 0,
        interval: 0,
        list: [],
      },
    });

    const footerRef = ref();
    const noticeRef = ref();
    const currYear = new Date().getFullYear();

    const snsAttrs = [{
      title: "뉴스레터",
      class: "news-letter",
      url: "https://ohmarket.stibee.com/",
      style: {
        backgroundImage: "url(/assets/ico/component.footer.sns.newsletter.svg)",
      },
    }, {
      title: "카카오 채널",
      class: "kakao",
      url: "https://pf.kakao.com/_xgUjxml",
      style: {
        backgroundImage: "url(/assets/ico/component.footer.sns.channel.svg)",
      },
    }, {
      title: "네이버 블로그",
      class: "blog",
      url: "https://blog.naver.com/omcofficial",
      style: {
        backgroundImage: "url(/assets/ico/component.footer.sns.blog.svg)",
      },
    }, {
      title: "네이버 포스트",
      class: "post d-none d-sm-inline-block",
      url: "https://post.naver.com/omcofficial",
      style: {
        backgroundImage: "url(/assets/ico/component.footer.sns.post.svg)",
      },
    }, {
      title: "인스타그램",
      class: "insta",
      url: "https://www.instagram.com/oh_mycompany",
      style: {
        backgroundImage: "url(/assets/ico/component.footer.sns.instagram.svg)",
      },
    }, {
      title: "페이스북",
      class: "facebook",
      url: "https://www.facebook.com/omc2012",
      style: {
        backgroundImage: "url(/assets/ico/component.footer.sns.facebook.svg)",
      },
    }];

    const move = (num) => {
      state.notice.idx += num;

      if (state.notice.idx < 0) {
        state.notice.idx = state.notice.length - 1;
      } else if (state.notice.idx > state.notice.length - 1) {
        state.notice.idx = 0;
      }
    };

    const clear = () => {
      clearInterval(state.notice.interval);
    };

    const resume = () => {
      state.notice.interval = setInterval(() => {
        state.notice.idx = state.notice.idx < state.notice.length - 1 ? state.notice.idx + 1 : 0;
      }, 2500);
    };

    onUnmounted(() => {
      clearInterval(state.notice.interval);
    });

    return {component, state, footerRef, noticeRef, currYear, snsAttrs, move, clear, resume};
  },
});
</script>

<style lang="scss" scoped>
.footer {
  background: $colorBackground;
  border-top: $px1 solid #eee;
  border-bottom: $px1 solid #eee;
  padding-top: $px40;
  padding-bottom: $px35;
  position: relative;

  .notice {
    position: relative;
    padding: 0 $px22 $px25 $px76;
    width: 100%;
    font-size: $px16;
    line-height: 1;

    .title {
      position: absolute;
      top: 0;
      left: 0;
      font-weight: 500;
      color: #3a3a3a;

      a {
        font-weight: 600;
      }

      span {
        vertical-align: top;
      }
    }

    .articles {
      height: $px21;
      overflow: hidden;

      ul {
        margin-top: 0;
        transition: margin-top 0.5s;

        li {
          height: $px21;

          a {
            display: inline-block;
            max-width: 100%;
            white-space: nowrap;
          }
        }
      }
    }

    //.control {
    //  position: absolute;
    //  top: $px-2;
    //  right: 0;
    //
    //  span {
    //    display: inline-block;
    //    width: $px20;
    //    height: $px20;
    //    background-repeat: no-repeat;
    //    background-size: $px15 $px9;
    //    background-position: 0 0;
    //    position: absolute;
    //    right: 0;
    //    opacity: 0.75;
    //
    //    &.up {
    //      top: 0;
    //    }
    //
    //    &.down {
    //      top: $px17;
    //    }
    //
    //    &:hover {
    //      opacity: 1;
    //    }
    //  }
    //}
  }

  .links {
    font-size: $px13;
    margin: 0 $px-5;

    ul {
      li {
        display: inline-block;
        vertical-align: middle;

        a {
          display: inline-block;
          padding: $px5;
          height: 100%;
        }
      }

      &.menus {
        float: left;

        > li {
          padding: $px3 $px5;

          &:first-child > a {
            padding-left: 0;
          }
        }
      }

      &.stores {
        float: right;
        margin-top: $px-10;

        > li {
          padding-left: $px10;

          > a {
            text-decoration: none;
            border: 0 solid #eee;
            background: rgba(255, 255, 255, 0.75);
            border-radius: $px4;
            color: #3e3e3e;
            padding: $px13 $px18;

            span {
              vertical-align: middle;

              &.img {
                width: $px17;
                height: $px17;
                margin-right: $px8;
              }
            }

            &:hover {
              background: #eee;
            }
          }
        }
      }
    }
  }

  .brands {
    padding-bottom: $px5;
    width: 100%;

    > a {
      display: inline-block;

      > img {
        width: $px164;
        height: $px29;
      }
    }

    > ul {
      &.sns {
        float: right;
        padding-top: $px9;

        > li {
          display: inline-block;
          padding-left: $px20;

          > a {
            .img {
              opacity: 0.75;
              transition: opacity 0.18s;
              width: $px20;
              height: $px20;

              &:hover {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }

  .texts {
    font-size: 0.78rem;
    padding-top: $px15;

    address {
      line-height: 1.8;
      margin-bottom: $px10;

      > span {
        display: inline-block;
        margin-right: $px10;
      }
    }
  }

  .info {
    line-height: 1.8;

    .warning {
      letter-spacing: $px-1;
    }
  }

  &[data-page=manage],
  &[data-page=admin],
  &[data-page=form] {
    background: none;
    color: $colorTextDefault;
  }

  @media (max-width: 991px) {
    .notice {
      font-size: $px14;
    }

    .links {
      text-align: center;

      ul {
        &.menus {
          margin-bottom: $px15;
          width: 100%;
        }

        &.stores {
          width: 100%;
          padding-top: $px15;
          padding-left: 0;

          > li {
            width: 50%;

            > a {
              width: 100%;
            }

            &:first-child {
              padding-left: 0;
            }
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    padding-top: $px21;
    padding-bottom: $px30;

    .notice {
      padding-left: $px70;
    }

    .links {
      padding-top: $px10;

      ul {
        &.menus {
          display: none;
        }

        &.stores {
          padding-top: 0;
          margin-bottom: $px15;
        }
      }
    }

    .brands {
      padding-top: $px5;
    }
  }

  @media(max-width: 991px) {
    &.mobile-margin-bottom {
      margin-bottom: $px60;
    }
  }
}
</style>