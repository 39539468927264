<template>
  <div class="list" :class="{skeleton : !state.loaded.init }">
    <div class="header">
      <div class="search container" v-if="projectType === 'search'">
        <div class="page-top">
          <div class="title">
            <span>검색 결과</span>
          </div>
          <div class="desc">
            <span>
              <span>"</span>
              <b>{{ state.args.searchKeyword }}</b>
              <span>"</span>
              <span>의 검색 결과 </span>
              <span class="color-point">{{ $lib.getNumberFormat(state.totalRecordCount) }}</span>
              <span>건을 찾았습니다.</span>
            </span>
          </div>
        </div>
        <div class="hashes" :class="{skeleton : !state.loaded.hashes }">
          <ul class="tight" v-if="state.loaded.hashes">
            <li v-for="(h, idx) of state.searchHashes" :key="idx">
              <router-link :to="`/main/search?searchKeyword=${h.tagname ? h.tagname.trim() : ''}`" class="btn font-xs" :class="{'bg-point border-point color-white': state.args.searchKeyword === h.tagname}">{{ h.tagname }}
              </router-link>
            </li>
          </ul>
          <ul class="hashes tight" v-else>
            <li v-for="i in 3" :key="i">
              <a class="btn font-sm">wait</a>
            </li>
          </ul>
        </div>
      </div>
      <template v-else>
        <div class="categories container" :class="{skeleton : !state.loaded.categories }">
          <ul class="tight no-scrollbar" v-if="state.loaded.categories">
            <li :class="{ active: state.args.projectCategory === c.codeId && state.args.fundType === c.fundingType }"
                v-for="c in state.categories" :key="c.codeId + c.fundingType">
              <router-link :to="getQuery(c.codeId, c.fundingType)" class="btn" :disabled="!state.loaded.init">
                <span class="img" :style="getImgStyle(c.codeIdName, c.fundingType)"></span>
                <span class="text">{{ c.codeIdName }}</span>
              </router-link>
            </li>
          </ul>
          <ul class="tight no-scrollbar" v-else>
            <li v-for="i in projectType === 'reward' ? 13 : 10" :key="i">
              <a class="btn" disabled>
                <span class="img"></span>
                <span class="text">wait</span>
              </a>
            </li>
          </ul>
        </div>
      </template>
    </div>
    <div class="article">
      <div class="container">
        <div class="acts clearfix">
          <div class="wrapper">
            <div class="left">
              <div class="desc font-sm">
                <span class="badge badge-point" title="검색 결과" v-if="projectType === 'search'">{{ state.args.searchKeyword }}</span>
                <span class="badge badge-point" title="카테고리" v-else>{{ state.activeCategoryTitle }}</span>
                <!-- <span class="badge badge-dark" title="프로젝트 합계" v-if="state.totalRecordCount !== null">{{ state.totalRecordCount > 99 ? "99건 +" : lib.getNumberFormat(state.totalRecordCount) + "건" }}</span>-->
              </div>
            </div>
            <div class="right font-sm">
              <div class="dropdown" v-if="projectType === 'invest'">
                <span class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{ computedStockType }}</span>
                <div class="dropdown-menu">
                  <a class="dropdown-item pointer" :class="{on: t.name === state.args.stockType}" @click="select(t.name)" v-for="(t, idx) in stockTypes" :key="idx">{{ t.title }}</a>
                </div>
              </div>
              <div class="dropdown">
                <span class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{ computedSorting }}</span>
                <div class="dropdown-menu">
                  <a class="dropdown-item pointer" :class="{ on: s.name === state.args.sorting }" @click="sort(s.name)" v-for="(s, idx) in sorts" :key="idx">{{ s.title }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="cards" ref="cardsRef">
          <template v-if="state.loaded.init">
            <ul class="tight clearfix" v-if="state.projects.length">
              <li v-for="(p, idx) in state.projects" :key="idx + 1"> <!-- idx + 1 하는 이유 : 하단의 스켈레톤 UI와 키를 맞추기 위해(progress-bar 애니메이션 효과 가능)-->
                <Card
                    :projectSeq="p.projectSeq"
                    :projectType="p.projectType"
                    :link="getLink(p)"
                    :thumbFilePath="p.thumbFilePath"
                    :amount="p.progressOrder === 1 ? p.projectType === 'reward' ? p.expenseAmt : p.expenseAmt : p.expenseAmt"
                    :projectName="p.projectName"
                    :progressOrder="p.progressOrder"
                    :simpleText="p.projectType === 'invest' ? (projectType === 'search' ? '' : undefined) : p.simpleText"
                    :projectCate="p.projectCateName"
                    :fundingType="p.fundingType"
                    :count="p.investorCount"
                    :percent="p.investRate"
                    :dday="Number(p.dday)"
                    :builderName="p.builderName"
                    :builderImageUrl="p.builderImageUrl"
                    :builderSeq="p.builderSeq"
                    :successFailName="p.successFailName"
                    :stockTypeText="p.stockTypeText"
                    :rewardFlag="p.rewardFlag"
                    :incomeDeductionFlag="p.incomeDeductionFlag"
                    :interest="true"
                    :privateEquityApprovedCnt="p.privateEquityApprovedCnt"
                    :privateEquityParticipantStatusCode="p.privateEquityParticipantStatusCode"
                    :stateHide="p.projectStateHideYn === 'Y'"
                    :mainImagePosition="p.mainImagePosition"
                />
              </li>
            </ul>
            <div class="pt-30 pb-30" v-else>
              <NoData/>
            </div>
            <ul class="tight clearfix" v-if="!state.loaded.more">
              <li v-for="i in 4" :key="i">
                <Card :skeleton="true" builderName="Wait a moment" projectName="Wait" projectCate="Wait"
                      simpleText="Please wait a moment" fundingType="A" :progressOrder="1" :count="100" :amount="100" :percent="10"
                />
              </li>
            </ul>
          </template>
          <ul class="tight clearfix" v-else>
            <li v-for="i in 4" :key="i">
              <Card :skeleton="true" builderName="wait a moment" projectName="wait" :projectType="projectType" projectCate="wait"
                    simpleText="Please wait a moment" fundingType="A" :stockTypeText="projectType === 'invest' ? 'Wait' : ''" :progressOrder="1" :count="0" :amount="0" :percent="0"
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, defineComponent, onMounted, reactive, ref, watch,} from "@vue/composition-api";
import http from "@/scripts/http";
import store from "@/scripts/store";
import router from "@/scripts/router";
import mixin from "@/scripts/mixin";
import {httpError} from "@/scripts/httpError";
import Card from "@/components/Card.vue";
import NoData from "@/components/NoData.vue";
import lib from "@/scripts/lib";
import track from "@/scripts/track";

function Component(initialize) {
  this.name = "pageProjectList";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {Card, NoData},
  props: {
    projectType: String,
  },
  setup(props) {
    const component = new Component(() => {
      sorts.push(...[
        {name: "", title: "추천순"},
        {name: "highest", title: "펀딩금액순"},
        {name: "impendence", title: "마감임박순"},
        {name: "latest", title: "최신순"},
      ]);

      switch (props.projectType) {
        case "reward":
          sorts.push(...[
            {name: "largest", title: "후원자순"},
          ]);
          break;

        case "invest":
          sorts.push(...[
            {name: "largest", title: "투자자순"},
          ]);
          break;
      }

      run();
      setCategories();
    });

    const state = reactive({
      categories: [],
      args: {
        projectType: "",
        pageIndex: 0,
        pageUnit: 24,
        sorting: "",
        projectCategory: "",
        fundType: "",
        stockType: "",
        searchKeyword: "",
        loginMemberSeq: "",
      },
      activeCategoryTitle: "전체",
      totalRecordCount: null,
      projects: [],
      searchHashes: [],
      loaded: {
        init: false,
        more: true,
        hashes: false,
        search: false,
        categories: false,
        completed: false,
      },
    });

    const cardsRef = ref();
    let firstProjectCardHeight = 0;

    const computedSorting = computed(() => {
      return sorts.find((s) => s.name === state.args.sorting)?.title;
    });

    const computedStockType = computed(() => {
      return stockTypes.find((s) => s.name === state.args.stockType)?.title;
    });

    const stockTypes = [
      {name: "", title: "증권 종류"},
      {name: "STCK_TP_0001", title: "채권"},
      {name: "STCK_TP_0002", title: "주식"},
    ];

    const sorts = [];

    const onScroll = () => {
      if (!state.loaded.init || !state.loaded.more) {
        return;
      }

      if (!firstProjectCardHeight) {
        firstProjectCardHeight = cardsRef.value.querySelector("li .card").offsetHeight;
      }

      (firstProjectCardHeight >= document.body.offsetHeight - (window.innerHeight + window.scrollY))
      && load();
    };

    const select = (stockType) => {
      if (state.args.stockType === stockType) {
        return;
      }

      const query = lib.getRenewed(router.app.$route.query);

      if (stockType) {
        query.stockType = stockType;
      } else {
        delete query.stockType;
      }

      router.push({path: router.app.$route.path, query});
    };

    const sort = (sort) => {
      if (state.args.sorting === sort) {
        return;
      }

      const query = lib.getRenewed(router.app.$route.query);

      if (sort) {
        query.sort = sort;
      } else if (query.sort) {
        delete query.sort;
      }

      router.push({path: router.app.$route.path, query});
    };

    const load = (init) => {
      if (init) {
        state.loaded.completed = false;
        state.loaded.init = false;
      } else if (state.loaded.completed) {
        return;
      } else {
        state.loaded.more = false;
      }

      let url = "";

      if (props.projectType === "search") {
        url = "/api/search/projects";
      } else {
        url = `/api/${props.projectType}/projects`;
        state.args.projectType = props.projectType;
      }

      state.args.pageIndex = init ? 1 : state.args.pageIndex + 1;

      http.get(url, state.args, {cache: true}).then(({data}) => {
        if (init) {
          state.projects = [];
          state.loaded.init = true;
        } else {
          state.loaded.more = true;
        }
        state.totalRecordCount = data.body.paginationInfo.totalRecordCount;

        for (let i in data.body.list) {
          state.projects.push(data.body.list[i]);
        }

        if (data.body.list.length < state.args.pageUnit) {
          state.loaded.completed = true;
        }
        init && track.post({
          name: `${props.projectType}ProjectList`,
          category: props.projectType === "search" ? "검색 결과" : props.projectType === "invest" ? "투자하기" : "후원하기",
          topic: "목록 조회",
          memo: props.projectType === "search" ? state.args.searchKeyword : null
        });
      }).catch(httpError());

      // http.get(`/api/${props.projectType}/projects/count`, state.args, {cache: true}).then(({data}) => {
      //   if (typeof data.body === "number") {
      //     state.totalRecordCount = data.body;
      //   }
      // }).catch(httpError());
    };

    const search = () => {
      state.args.searchKeyword = router.app.$route.query.searchKeyword;
      load(true);
    };

    const run = () => {
      if (router.app.$route.query.sort) {
        state.args.sorting = router.app.$route.query.sort;
      } else if (state.args.projectCategory || props.projectType === "invest") {
        state.args.sorting = "";
      } else {
        state.args.sorting = "";
      }

      state.args.stockType = router.app.$route.query.stockType || "";

      if (props.projectType === "search") {
        state.loaded.hashes = false;
        http.get("/api/search/projects/hashes", state.args, {cache: true}).then(({data}) => {
          state.loaded.hashes = true;
          state.searchHashes = data.body;
        }).catch(httpError());
        search();
      } else {
        state.args.fundType = router.app.$route.query.fundType || "";
        state.args.projectCategory = router.app.$route.query.category || "";
        load(true);
      }
    };

    const setCategories = () => {
      if (props.projectType === "search") {
        return;
      }

      state.loaded.categories = false;
      http.get(`/api/${props.projectType}/categories`, undefined, {cache: true}).then(({data}) => {
        state.loaded.categories = true;

        for (let d of data.body) {
          d.fundingType = "";
          state.categories.push(d);
        }

        state.categories.unshift({
          codeId: "",
          codeIdName: "전체",
          active: true,
          fundingType: "",
        });

        if (props.projectType === "invest") {
          state.categories.push({
            codeId: "",
            codeIdName: "사모",
            active: false,
            fundingType: "02",
          });
        }

        activateCategory();
      }).catch(httpError());
    };

    const activateCategory = () => {
      if (props.projectType === "search") {
        return;
      }

      state.activeCategoryTitle = state.categories.find(i => i.codeId === state.args.projectCategory)?.codeIdName;
    };

    const getLink = (project) => {
      if (project.projectType === "invest" && project.fundingType === "02" && project.progressOrder === 1 && project.privateEquityParticipantStatusCode !== 1) {
        return undefined;
      }

      return `/${project.projectType}/${project.projectSeq}`;
    };

    const getQuery = (val1, val2) => {
      const queries = [];

      if (val1) {
        queries.push("category=" + val1);
      }

      if (val2) {
        queries.push("fundType=" + val2);
      }

      if (queries.length) {
        return "?" + queries.join("&");
      }

      return "";
    };

    const getImgStyle = (category, fundingType) => {
      let backgroundImgName = "";
      let backgroundSize = "";

      if (fundingType === "02") {
        backgroundImgName = "page.project.list.category.impact.svg";
        backgroundSize = "33px";
      } else if (category.includes("리빙")) {
        backgroundImgName = "page.project.list.category.living.svg";
      } else if (category.includes("환경")) {
        backgroundImgName = "page.project.list.category.env.svg";
      } else if (category.includes("푸드")) {
        backgroundImgName = "page.project.list.category.food.svg";
      } else if (category.includes("예술")) {
        backgroundImgName = "page.project.list.category.art.svg";
        backgroundSize = "33px";
      } else if (category.includes("문화")) {
        backgroundImgName = "page.project.list.category.culture.svg";
      } else if (category.includes("뷰티")) {
        backgroundImgName = "page.project.list.category.beauty.svg";
      } else if (category.includes("반려동물")) {
        backgroundImgName = "page.project.list.category.pet.svg";
      } else if (category.includes("공간")) {
        backgroundImgName = "page.project.list.category.home.svg";
        backgroundSize = "33px";
      } else if (category.includes("소셜")) {
        backgroundImgName = "page.project.list.category.social.svg";
      } else if (category.includes("지역") || category.includes("로컬")) {
        backgroundImgName = "page.project.list.category.location.svg";
      } else if (category.includes("여행")) {
        backgroundImgName = "page.project.list.category.airplane.svg";
      } else if (category.includes("영화")) {
        backgroundImgName = "page.project.list.category.media.svg";
      } else if (category.includes("교육")) {
        backgroundImgName = "page.project.list.category.edu.svg";
      } else if (category.includes("테크")) {
        backgroundImgName = "page.project.list.category.tech.svg";
      }

      return {
        backgroundSize,
        backgroundImage: backgroundImgName ? `url(/assets/ico/${backgroundImgName})` : undefined
      };
    };

    watch(() => router.app.$route.query, (next, prev) => {
      if ((next.modals || prev.modals) && prev.searchKeyword === next.searchKeyword) {
        return;
      }

      run();
      activateCategory();
    });

    onMounted(() => {
      store.commit("addListener", [component.name, "onScroll", onScroll]);
    });

    return {component, state, cardsRef, computedSorting, computedStockType, sorts, stockTypes, select, getLink, getQuery, getImgStyle, sort, run, search};
  },
});
</script>

<style scoped lang="scss">
.list {
  .header {
    .page-top {
      padding-bottom: $px25;
    }

    .search {
      text-align: center;
      padding-top: $px35;

      .hashes {
        > ul > li {
          display: inline-block;
          margin: $px5;

          a {
            display: inline-block;
            padding: 0 $px8;
            height: $px33;
            line-height: $px31;
            border: $px1 solid #eee;
            border-radius: $px20;
            text-decoration: none;
            font-size: 0.85rem;
            color: #666;

            &:hover {
              border-color: #ddd;
              background-color: $colorBackground;
            }
          }
        }

        &.skeleton {
          > ul > li > a {
            @include skeleton;
          }
        }
      }
    }

    > .slider {
      border-bottom: $px1 solid #eee;
    }

    > .categories {
      padding-top: $px21;
      padding-bottom: $px15;
      text-align: center;

      ul {
        white-space: nowrap;
        overflow: auto;

        li {
          display: inline-block;
          padding: $px10 $px5;
          opacity: 0.85;

          a {
            font-size: $px14;
            line-height: $px12;
            text-align: center;
            text-decoration: none;
            padding: 0;
            color: #555;

            .img {
              background-image: url(/assets/ico/page.project.list.category.total.svg);
              background-repeat: no-repeat;
              background-position: 50% 50%;
              display: inline-block;
              height: $px45;
              margin-bottom: $px7;
              width: $px54;
              background-size: auto $px30;

              &.active {
                width: $px1;
                height: $px1;
              }
            }

            .text {
              font-size: $px14;
              white-space: nowrap;
              display: block;
            }

            &:focus {
              box-shadow: none;
            }

            &:not([disabled]):hover {
              font-weight: 500;
            }
          }

          &.active {
            opacity: 1;

            a {
              font-weight: 600;
            }
          }
        }
      }

      &.skeleton {
        ul li a {
          span {
            @include skeleton;

            &.img {
              border-radius: $px4;
            }

            &.text {
              color: transparent;
            }
          }
        }
      }
    }
  }

  .article {
    padding-bottom: $px35;

    .acts {
      padding-top: $px20;
      padding-bottom: $px3;
      width: 100%;

      > .wrapper {
        height: $px23;

        .left {
          float: left;

          .desc span {
            font-weight: normal;
            margin-right: $px4;
            padding: $px5;
            border-radius: $px6;
            max-width: $px100;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }

        .right {
          float: right;

          .dropdown {
            display: inline-block;
            margin-left: $px30;

            span {
              cursor: pointer;
            }

            .dropdown-menu {
              padding: 0;
              top: $px27 !important;
              left: auto !important;
              right: 0 !important;
              transform: none !important;
              min-width: $px120;
              border-color: $colorPoint;
              border-radius: $px2;

              a {
                font-size: $px14;
                padding: $px7 $px10;

                &:hover {
                  background-color: $colorBackground;
                }

                &:active {
                  color: inherit;
                }

                &.on {
                  background-color: $colorPoint;
                  color: #fff;
                }
              }
            }
          }
        }
      }
    }

    .cards {
      ul {
        margin: 0 $px-10;

        li {
          float: left;
          width: calc(100% / 4 - 0.1px);
          padding: $px10 $px10 $px30 $px10;
        }
      }
    }
  }

  &.skeleton {
    .header {
      .page-top .title > span,
      .page-top .desc > span {
        @include skeleton;
        color: transparent;
      }

      .page-top .desc > span span {
        color: transparent !important;
      }
    }

    .article .acts > .wrapper {
      .left .desc span {
        @include skeleton;
      }

      .right .dropdown span {
        @include skeleton;
      }
    }

  }

  @media (max-width: 991px) {
    .header {
      > .categories {
        padding-top: $px15;
        padding-left: $px5;

        ul {
          margin-right: $px-15;

          li {
            padding-left: $px3;
            padding-right: $px3;

            &:last-child {
              margin-right: $px15;
            }
          }
        }
      }
    }

    .article {
      .cards ul li {
        width: 50%;
        padding-bottom: $px5;
      }
    }
  }

  @media (max-width: 767px) {
    .header > .categories ul li a {
      .img {
        height: $px40;
        width: $px40;
        margin-bottom: $px3;
        background-size: auto $px25;
      }

      .text {
        font-size: $px12;
      }
    }

    .article {
      .acts {
        padding-top: $px25;
      }

      .cards ul li {
        width: 100%;
      }
    }
  }
}
</style>