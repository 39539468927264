<template>
  <div class="manage" :data-toggle="state.layout.toggle ? 'Y' : 'N'">
    <template v-if="$store.state.account.loggedIn">
      <template v-if="state.project.loaded">
        <template v-if="computedManager">
          <div class="wrapper">
            <Aside :layout="state.layout" :toggle="toggle" :menus="computedMenus"/>
            <Header :layout="state.layout" :toggle="toggle" :project="state.project" :projectType="$route.params.projectType"/>
            <Main :menu="computedMenu">
              <RouterView :project="state.project"/>
            </Main>
            <Footer page="manage"/>
          </div>
        </template>
        <div class="no-auth" v-else>
          <div>
            <img src="/assets/ico/common.warning.sign.svg"/>
            <div class="pt-3">이 페이지에 접근할 권한이 없습니다.</div>
            <div class="pt-3">
              <router-link class="color-anchor" to="/">홈으로</router-link>
              <a class="pointer ml-3" @click="$store.dispatch('logoutAccount')">
                <span class="color-anchor">로그아웃</span>
              </a>
            </div>
          </div>
        </div>
        <Climbing/>
      </template>
    </template>
    <NoLogin v-else/>
  </div>
</template>

<script>
import {computed, defineComponent, onMounted, onUnmounted, reactive} from "@vue/composition-api";
import store from "@/scripts/store";
import router from "@/scripts/router";
import Header from "@/layouts/manage/Header";
import Aside from "@/layouts/manage/Aside";
import Main from "@/layouts/manage/Main";
import Footer from "@/components/Footer";
import mixin from "@/scripts/mixin";
import NoLogin from "@/components/NoLogin";
import http from "@/scripts/http";
import {httpError} from "@/scripts/httpError";
import lib from "@/scripts/lib";
import Climbing from "@/components/Climbing";
import definitions from "../../scripts/definitions";

function Component(initialize) {
  this.name = "layoutManage";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {Climbing, Main, NoLogin, Header, Aside, Footer},
  setup() {
    const component = new Component(async () => {
      if (!store.state.account.loggedIn) {
        // return store.commit("openModal", {
        //   name: "Login",
        //   routerReplace: true
        // });

        return store.dispatch("goLoginPage", {replace: true});
      }

      const res1 = await http.get(`/api/maker/${projectType}/${projectSeq}/stats`, undefined, {cache: true}).catch(httpError());

      if (res1?.error) {
        state.project.loaded = true;
        return;
      }

      state.project = res1.data.body.project;
      state.project.loaded = true;
    });

    const state = reactive({
      layout: {
        toggle: false,
      },
      project: {
        projectName: "Please wait a moment",
        simpleText: "Wait a moment",
        loaded: false,
      }
    });

    const projectType = router.app.$route.params.projectType;
    const projectSeq = router.app.$route.params.projectSeq;

    const argonHref = "/assets/lib/argon/argon.min.css";
    const nucleoHref = "/assets/lib/nucleo/css/nucleo.css";

    const computedManager = computed(() => {
      if (store.state.account.memberAuth === "MEMBER_MID_ADMIN" || store.state.account.memberAuth === "MEMBER_TOP_ADMIN") {
        return true;
      } else if (router.app.$route.params.projectType === "invest") {
        return state.project.memberSeq === store.state.account.memberSeq;
      } else {
        return state.project.openId === store.state.account.memberSeq;
      }
    });

    const computedMenus = computed(() => {
      const paths = router.app.$route.path.split("/").filter(p => p && p !== "manage");
      const path1 = paths[2];
      const path2 = paths[3];

      const menus = [{
        name: "home",
        title: "대시보드",
        icon: "ni-shop",
        color: "text-primary",
        path: `/manage/${projectType}/${projectSeq}`,
        active: !path1,
        visible: true,
        children: [],
      }, {
        name: "community",
        title: "프로젝트 관리",
        icon: "ni-ungroup",
        color: "text-orange",
        path: `/manage/${projectType}/${projectSeq}/news`,
        active: false,
        visible: true,
        children: [{
          name: "news",
          title: "최근 소식",
          icon: "",
          color: "",
          path: `/manage/${projectType}/${projectSeq}/news`,
          active: path1 === "news",
          visible: true,
          children: []
        }, {
          name: "qna",
          title: "Q&A",
          icon: "",
          color: "",
          path: `/manage/${projectType}/${projectSeq}/qna`,
          active: path1 === "qna",
          visible: true,
          children: []
        },
        ]
      }, {
        name: "investor",
        title: `${projectType === "reward" ? "후원자" : "투자자"} 관리`,
        icon: "ni-paper-diploma",
        color: "text-green",
        path: `/manage/${projectType}/${projectSeq}/investor`,
        active: false,
        visible: true,
        children: [{
          name: "investor",
          title: [`${projectType === "reward" ? "후원자" : "투자자"} 목록`, `${projectType === "reward" ? "후원" : "투자"} 정보`],
          icon: "",
          color: "",
          path: `/manage/${projectType}/${projectSeq}/investor`,
          active: path1 === "investor" && path2,
          visible: false,
          children: []
        }, {
          name: "investor",
          title: `${projectType === "reward" ? "후원자" : "투자자"} 목록`,
          icon: "",
          color: "",
          path: `/manage/${projectType}/${projectSeq}/investor`,
          active: path1 === "investor",
          visible: true,
          children: []
        }]
      }, {
        name: "statistics",
        title: "프로젝트 통계",
        icon: "ni-chart-pie-35",
        color: "text-info",
        path: `/manage/${projectType}/${projectSeq}/stats`,
        active: false,
        visible: true,
        children: [{
          name: "stats",
          title: "일별 통계",
          icon: "",
          color: "",
          path: `/manage/${projectType}/${projectSeq}/stats`,
          active: path1 === "stats" && !path2,
          visible: true,
          children: []
        }]
      }, {
        name: "calculate",
        title: "프로젝트 정산",
        icon: "ni-money-coins",
        color: "text-pink",
        path: `/manage/${projectType}/${projectSeq}/calculate`,
        active: true,
        visible: false,
        children: []
      }, {
        name: "calculateNew",
        title: "프로젝트 정산",
        icon: "ni-money-coins",
        color: "text-pink",
        path: `/manage/${projectType}/${projectSeq}/calculateNew`,
        active: true,
        visible: projectType === "reward",
        children: []
      }, {
        name: "manager",
        title: "진행자 정보",
        icon: "ni-archive-2",
        color: "color-point",
        path: `/manage/${projectType}/${projectSeq}/manager`,
        active: false,
        visible: true,
        children: []
      }];

      if (projectType === "reward" || projectType === "mock") {
        menus.find(m => m.name === "community").children.push(...[{
          name: "cheer",
          title: "후원자 응원",
          icon: "",
          color: "",
          path: `/manage/${projectType}/${projectSeq}/cheer`,
          active: path1 === "cheer",
          visible: true,
          children: []
        }, {
          name: "review",
          title: "후원자 후기",
          icon: "",
          color: "",
          path: `/manage/${projectType}/${projectSeq}/review`,
          active: path1 === "review",
          visible: true,
          children: []
        }, {
          name: "introduceCheer",
          title: "응원 한마디",
          icon: "",
          color: "",
          path: `/manage/${projectType}/${projectSeq}/introduceCheer`,
          active: path1 === "introduceCheer",
          visible: true,
          children: []
        }, {
          name: "story",
          title: "스토리(준비중)",
          icon: "",
          color: "",
          path: `/manage/${projectType}/${projectSeq}/story`,
          active: path1 === "story",
          visible: true,
          children: []
        }]);

        // 후원형 인 경우에만 메뉴 활성화
        if (projectType === "reward") {
          // 협업지원을 사용할 경우에만 메뉴 활성화
          if (state.project.crowdYn === "Y") {
            const idx = menus.find(m => m.name === "community")?.children.findIndex(m => m.name == "story");
            menus.find(m => m.name === "community").children.splice(idx, 0, {
              name: "crowdSourcing",
              title: "협업지원",
              icon: "",
              color: "",
              path: `/manage/${projectType}/${projectSeq}/crowd-sourcing`,
              active: path1 === "crowd-sourcing",
              visible: true,
              children: []
            });
          }

          if (window.Number(state.project.projectSeq) === definitions.pickCalendar.projectSeq) {
            menus.find(m => m.name === "community").children.push({
              name: "pickCalendarStatus",
              title: "예약 현황",
              icon: "",
              color: "",
              path: `/manage/${projectType}/${projectSeq}/pick-calendar-status`,
              active: path1 === "pick-calendar-status",
              visible: true,
              children: []
            });
          }

          menus.find(m => m.name === "investor").children.push(...[{
            name: "delivery",
            title: "리워드 제공 관리",
            icon: "",
            color: "",
            path: `/manage/${projectType}/${projectSeq}/delivery`,
            active: path1 === "delivery",
            visible: true,
            children: []
          }]);

          menus.find(m => m.name === "statistics").children.push(...[{
            name: "rewards",
            title: "리워드별 통계",
            icon: "",
            color: "",
            path: `/manage/${projectType}/${projectSeq}/stats/rewards`,
            active: path1 === "stats" && path2 === "rewards",
            visible: true,
            children: []
          }, {
            name: "paymethod",
            title: "결제수단별 통계",
            icon: "",
            color: "",
            path: `/manage/${projectType}/${projectSeq}/stats/paymethod`,
            active: path1 === "stats" && path2 === "paymethod",
            visible: true,
            children: []
          }]);
        } else if (projectType === "mock") {
          // 모의투자인 경우 정산메뉴를 비활성화 한다.
          menus.find(m => m.name === "calculate").visible = false;
          menus.find(m => m.name === "calculate").active = false;
        }
      } else {
        menus.find(m => m.name === "statistics").children.push(...[{
          name: "investorBySort",
          title: "유형별 통계",
          icon: "",
          color: "",
          path: `/manage/${projectType}/${projectSeq}/stats/investorBySort`,
          active: path1 === "stats" && path2 === "investorBySort",
          visible: true,
          children: []
        }]);
      }

      for (let i in menus) {
        if (menus[i].children.length) {
          for (let j in menus[i].children) {
            if (menus[i].children[j].active) {
              menus[i].active = true;
              break;
            }
          }
        } else if (menus[i].path === router.app.$route.path) {
          menus[i].active = true;
          break;
        }
      }

      return menus;
    });

    const computedRouteMatchedComponentName = computed(() => {
      return router.app.$route.matched?.length ? router.app.$route.matched[0]?.components?.default?.name : "";
    });

    const computedMenu = computed(() => {
      const titles = [];
      const menu = lib.getRenewed(computedMenus.value.find(m => m.active));

      if (menu) {
        titles.push(menu.title);

        if (menu.children.length) {
          const subTitle = menu.children.find(m => m.active)?.title;

          if (subTitle) {
            Array.isArray(subTitle) ? titles.push(...subTitle) : titles.push(subTitle);
          }
        }

        menu.titles = titles;
      }

      return menu;
    });

    const toggle = () => {
      state.layout.toggle = !state.layout.toggle;
    };

    onMounted(() => {
      if (!store.state.account.loggedIn) {
        return;
      }

      store.dispatch("appendLink", {href: nucleoHref});
      store.dispatch("appendLink", {href: argonHref});
    });

    onUnmounted(() => {
      document.head.querySelector(`link[href='${argonHref}']`)?.remove();
      document.head.querySelector(`link[href='${nucleoHref}']`)?.remove();
    });

    return {component, state, computedManager, computedMenus, computedRouteMatchedComponentName, computedMenu, toggle};
  },
});
</script>

<style lang="scss" scoped>
.manage {
  color: #525f7f;

  > .wrapper {
    background-color: #f8f9fe;
    padding-left: $px250;
    position: relative;

    .btn {
      box-shadow: none;
    }

    .view {
      margin-top: $px-60;
    }

    &.no-login {
      padding-left: 0;
    }
  }

  > .no-auth {
    display: table;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;

    > div {
      display: table-cell;
      vertical-align: middle;

      img {
        height: $px50;
      }
    }
  }

  @media(max-width: 991px) {
    overflow-x: hidden;

    > .wrapper {
      padding-left: 0;
      left: 0;
      transition: left 0.25s;
    }

    &[data-toggle=Y] > .wrapper {
      left: $px250;
    }
  }
}
</style>